<template>
	<v-footer id="default-footer" color="transparent" absolute app inset>
		<v-row justify="end">
			<v-col cols="auto">
<!--				<ti-toast></ti-toast>-->
			</v-col>
		</v-row>

	</v-footer>
</template>

<script>
export default {
	name: 'DefaultFooter',
}
</script>